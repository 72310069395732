#grid sup {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.widget-container {
  margin-right: 320px;
}

.current-product {
  padding-top: 11px;
}

.current-value {
  font-weight: bold;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);


}

.caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 10px;
}
.dx-toolbar .dx-toolbar-items-container {
  /* height: 55px; */
  overflow: visible;
  padding: 28px;

}
.first-group,
.second-group {
  padding: 20px;
}

.second-group {
  background-color: rgba(191, 191, 191, 0.15);
}

.form-avatar {
  height: 128px;
  width: 128px;
  margin-right: 10px;
  border: 1px solid #d2d3d5;
  border-radius: 50%;
  background-image: url('../../../public/assets/profile.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.left {
  float: left;
  width: 330px;
  height: 470px;
  padding: 20px;
  background: rgba(191, 191, 191, 0.15);
  margin-right: 30px;
}

.right {
  overflow: hidden;
}

.left .list .dx-list-group-header {
  color: #f05b41;
  font-weight: normal;
  font-size: 18px;
}

.left .list .hotel {
  float: left;
}

.left .list .hotel .name {
  font-weight: bold;
}

.right .header {
  height: 70px;
}

.right .header .name-container {
  float: left;
}

.right .header .name {
  font-size: 30px;
  font-weight: bold;
}

.right .header .price-container {
  padding-top: 27px;
}

.right .name-container .type {
  margin-top: 0;
}

.right .tile {
  margin: 0 -12px 0 -12px;
}

.right .tile-image {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.right .address {
  padding-top: 30px;
  font-size: 18px;
  opacity: 0.7;
}

.right .description {
  margin: 10px 0;
}

.type {
  margin: 3px 0 5px 0;
  height: 14px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2218px%22%20height%3D%2214px%22%20%3E%3Cpolyline%20fill%3D%22%23F05B41%22%20points%3D%227.5%2C0%209.972%2C4.399%2014.999%2C5.348%2011.5%2C9.015%2012.135%2C14%207.5%2C11.866%202.865%2C14%203.5%2C9.015%200.001%2C5.348%205.028%2C4.399%207.5%2C0%20%22%2F%3E%3C%2Fsvg%3E');
  background-size: 18px 14px;
}

.type.gold {
  width: 54px;
}

.type.platinum {
  width: 72px;
}

.type.diamond {
  width: 90px;
}

.price-container {
  float: right;
  padding-top: 13px;
}

.price-container > div {
  display: inline-block;
}

.price-container .price {
  font-size: 25px;
}

.price-container .caption {
  font-size: 11px;
  line-height: 12px;
  padding-left: 2px;
  opacity: 0.7;
}
