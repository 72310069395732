#grid sup {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.widget-container {
  margin-right: 320px;
}

.current-product {
  padding-top: 11px;
}

.current-value {
  font-weight: bold;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);


}

.caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 10px;
}
.dx-toolbar .dx-toolbar-items-container {
  /* height: 55px; */
  overflow: visible;
  padding: 28px;

}
.dx-overlay-content.dx-resizable.dx-loadpanel-content{
  z-index:20001 !important
}